import React, {Component} from "react";

import "../styles/main.css";

class Spinner extends Component {

	render() {
		return (
			<div className="make_modal">
				<div className="loading_spinner"/>
			</div>
		);
	}

}

export default Spinner;
