
export const handlePremiumResults = (premResults) => ({
	type: "ADD_RESULT",
	premResults
});

export const handleCountyProductsResults = (countyProductsResults) => ({
	type: "ADD_COUNTY_RESULT",
	countyProductsResults
});
